<template>
  <div class="punocomponent">
    <!-- Title -->
    <div class="punoheader">
      <div class="">
        <h1 class="">
          {{ $t("puno.title") }}
        </h1>
        <p class="">
          {{ $t("puno.p") }}
        </p>
      </div>
    </div>
    <!-- Puno -->
    <!-- Amantani -->
    <div class="punosect">
    <div class="section1">
      <!-- <div class="sqr"> -->
        <div
          class="sqrcont1"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/amantani.jpg"
              class="imgpuno"
              alt=""
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 style="margin-bottom: 0%">
              {{ $t("puno.amantani.title") }}
            </h2>
            <p class="">{{ $t("puno.amantani.p") }}</p>
          </div>
        </div>
      <!-- </div> -->
    </div>
    <!-- Taquile -->
    <div class="section2">
      <!-- <div class=""> -->
        <div
          class="sqrcont2"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/taquile.jpg"
              class=""
              alt=""
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.taquile.title") }}
            </h2>
            <p class="">{{ $t("puno.taquile.p") }}</p>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- Uros -->
    <div class="section1">
      <!-- <div class=""> -->
        <div
          class="sqrcont1"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/uros.jpg"
              class=""
              alt=""
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.uros.title") }}
            </h2>
            <p class="">{{ $t("puno.uros.p") }}</p>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- Cathedral -->
    <div class="section2">
      <!-- <div class=""> -->
        <div
          class="sqrcont2"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/catedral.jpg"
              class=""
              alt="Catedral de Puno"
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.cathedral.title") }}
            </h2>
            <p class="">{{ $t("puno.cathedral.p") }}</p>
          </div>
        </div>
      <!-- </div> -->
    </div>
    <!-- Santuario -->
    <div class="section1">
      <!-- <div class=""> -->
        <div
          class="sqrcont1"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/santuario.jpg"
              class=""
              alt="Santuario Virgen de la Candelaria"
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.snjn.title") }}
            </h2>
            <p class="">{{ $t("puno.snjn.p") }}</p>
          </div>
        </div>
      <!-- </div> -->
    </div>
    <!-- Puma -->
    <div class="section2">
      <!-- <div class=""> -->
        <div
          class="sqrcont2"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/pumauta.jpg"
              class=""
              alt="Mirador Puma Uta"
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.puma.title") }}
            </h2>
            <p class="">{{ $t("puno.puma.p") }}</p>
          </div>
        </div>
      <!-- </div> -->
    </div>
    <!-- kuntur -->
    <div class="section1">
      <!-- <div class=""> -->
        <div
          class="sqrcont1"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/condormirador.jpg"
              class=""
              alt=""
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.kuntur.title") }}
            </h2>
            <p class="">{{ $t("puno.kuntur.p") }}</p>
          </div>
        </div>
      <!-- </div> -->
    </div>
    <!-- Arco -->
    <div class="section2">
      <!-- <div class=""> -->
        <div
          class="sqrcont2"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/arco.jpg"
              class=""
              alt="Catedral de Puno"
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.arco.title") }}
            </h2>
            <p class="">{{ $t("puno.arco.p") }}</p>
          </div>
        </div>
      <!-- </div> -->
    </div>
    <!-- sillustani -->
    <div class="section1">
      <!-- <div class=""> -->
        <div
          class="sqrcont1"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/sillustani.jpg"
              class=""
              alt=""
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.sillustani.title") }}
            </h2>
            <p class="">{{ $t("puno.sillustani.p") }}</p>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- Molloco -->
    <div class="section2">
      <!-- <div class=""> -->
        <div
          class="sqrcont2"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/molloco.jpg"
              class=""
              alt="Catedral de Puno"
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.molloco.title") }}
            </h2>
            <p class="">{{ $t("puno.molloco.p") }}</p>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- lampa -->
    <div class="section1">
      <!-- <div class=""> -->
        <div
          class="sqrcont1"
        >
          <div class="sqrimg">
            <img
              src="../../assets/fotos/lampa.jpg"
              class=""
              alt=""
            />
          </div>
          <div
            class="sqrtext"
          >
            <h2 class="">
              {{ $t("puno.lampa.title") }}
            </h2>
            <p class="">{{ $t("puno.lampa.p") }}</p>
          </div>
        <!-- </div> -->
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.punocomponent{
  margin-top: 50px;
}
.punoheader{
  text-align: center;
}
.section2{
padding: 1rem 0;
}
.section1{
  background-color: #fffbe8;
  padding: 1rem 0;
}
.sqrcont2{
  display: flex;
}
.sqrcont1{
  display: flex;
}
.sqrimg{
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.sqrtext{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .sqrcont1, .sqrcont2{
    flex-direction: row;
  }
  .sqrtext{
    padding: 0px 30px;
    flex: 2;
  }
  .sqrimg{
  /* display: flex; */
    flex:3;
    /* overflow: hidden; */
    /* align-items: center; */
    /* justify-content: center; */
    height:20rem;
  }
  .sqrimg img{
    width: 100%;
  }
}
@media (min-width: 800px) and (max-width: 991px) {
  /* .sqrcont1, .sqrcont2{
    flex-direction: row;
  } */
  .sqrtext{
    padding: 0px 30px;
    flex: 3;
  }
  .sqrimg{
    /* display: flex; */
    flex:2;
        height:10rem;
    /* overflow: hidden; */
    /* align-items: center; */
    /* justify-content: center; */
  }
  .sqrimg img{
    width: 100%;
  }
}
/* small-tablet-portrait: min:600px - max:799px; */
@media (min-width: 600px) and (max-width: 799px) {
.sqrcont1, .sqrcont2{
    flex-direction: row;
  }
  .sqrtext{
    padding: 0px 30px;
    flex: 3;
  }
  .sqrimg{
    /* display: flex; */
    flex: 2;
        height:10rem;

    /* overflow: hidden;
    align-items: center;
    justify-content: center; */
  }
  .sqrimg img{
    width: 100%;
  }
}
/* mobile-landscape: min:480px - max:599px; */
@media (min-width: 480px) and (max-width: 599px) {
 .sqrcont2{
    flex-direction: column;
  }
  .sqrcont1{
    flex-direction: column;
  }
  .sqrtext{
    padding: 10px 25px;
    flex: 1;
  } 
  .sqrimg{
    /* flex: 1; */
        height:20rem;
  }
  .sqrimg img{
    width: 100%;
  }
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
.sqrcont2{
    flex-direction: column;
  }
  .sqrcont1{
    flex-direction: column;
  }
  .sqrtext{
    padding: 0px 25px;
    flex: 1;
  } 
  .sqrimg{
    /* flex: 1; */
    height:15rem;
  }
  .sqrimg img{
    width: 100%;
  }
}
</style>