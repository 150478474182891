<template>
  <div class="puno">
    <PunoC/>
  </div>
</template>

<script>
import PunoC from '../components/Puno/PunoC.vue'
export default {
  name: "Puno",
  components: {
    PunoC
  }
}
</script>

<style>


</style>